import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { OrganizationModel } from '@nimles/models/lib/organization-management';
import { State } from '@nimles/react-redux';

export const CompanyRoute = ({ component: Component, ...props }) => {
  const { selected: organization, loaded } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);

  if (loaded && !organization?.id) {
    navigate('/connect');
    return null;
  }

  return <Component {...props} />;
};
