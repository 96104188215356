import React from 'react';
import { Field } from 'formik';
import styled from '@emotion/styled';
import 'react-quill/dist/quill.snow.css';
const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;
import 'react-quill/dist/quill.snow.css';

const ReactQuillContainer = styled.div`
  background: ${({ theme }) => theme.input.background};
`;

const StandardInput = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  return (
    <div key={name}>
      {props.label ? <label>{props.label}</label> : null}
      <ReactQuillContainer>
        <ReactQuill
          id={name}
          {...props}
          value={value}
          onChange={handleChange}
        />
        <div>{errors[name]}</div>
      </ReactQuillContainer>
    </div>
  );
};

export const QuillField = (props) => (
  <Field {...props} component={StandardInput} />
);
