import React, { useEffect } from 'react';
import { OrderModel, QuoteRequestModel } from '@nimles/models/lib/e-commerce';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Column,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { navigate } from 'gatsby';
import { loadQuoteRequests } from '@nimles/react-redux';
import moment from 'moment';
import { CategoryModel } from '@nimles/models/lib/organization-management';
import { ProductGroupModel } from '@nimles/models/lib/pim';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { QuoteRequest } from './QuoteRequest';
import { CompanyRoute } from '../CompanyRoute';
import { Router } from '@reach/router';

export const QuoteRequests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );

  return (
    <Row>
      <Column>
        <Card>
          <CardHeader>
            <CardTitle>{t('title.quoteRequests')}</CardTitle>
          </CardHeader>
          <CardBody>
            <List>
              {quoteRequests.map(
                ({
                  id,
                  head: { quoteRequestNumber, submittedDate },
                  lines,
                }) => {
                  const groups = lines.map(
                    ({ id, categoryId, productGroupId }) =>
                      `${
                        publicCategories.find(({ id }) => id === categoryId)
                          ?.name ?? ''
                      } ${
                        publicProductGroups.find(
                          ({ id }) => id === productGroupId
                        )?.name ?? ''
                      }`
                  );

                  return (
                    <ListItem
                      key={id}
                      onClick={() => navigate('/company/requests/' + id)}
                    >
                      <Row justify="space-between">
                        <Column>
                          {lines.map(({ id, categoryId, productGroupId }) => {
                            const category = publicCategories.find(
                              ({ id }) => id === categoryId
                            );
                            const productGroup = publicProductGroups.find(
                              ({ id }) => id === productGroupId
                            );

                            return (
                              <div>
                                <strong>{category?.name ?? ''}</strong>
                                <div>{productGroup?.name ?? ''}</div>
                              </div>
                            );
                          })}
                        </Column>
                        <Column>{moment(submittedDate).format('MMM D')}</Column>
                      </Row>
                    </ListItem>
                  );
                }
              )}
            </List>
          </CardBody>
        </Card>
      </Column>
      <Column flex>
        <Router>
          <CompanyRoute path="/:quoteRequestId" component={QuoteRequest} />
        </Router>
      </Column>
    </Row>
  );
};
