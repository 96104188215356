import styled from '@emotion/styled';
import { Button } from '@nimles/react-web-components';
import { Link } from 'gatsby-plugin-react-i18next';
import { transparentize } from 'polished';
import isPropValid from '@emotion/is-prop-valid';

export const Navbar = styled.div`
  padding: 20px 0 0;
  background: #fff;
  margin-bottom: 30px;
`;

export const NavbarLink = styled(Link, {
  shouldForwardProp: isPropValid,
})<{ isActive?: boolean }>`
  text-decoration: none;
  font-family: sans-serif;
  font-size: 14px;
  padding: 10px 20px 6px;
  border-bottom: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary.color : 'transparent'};
  margin-bottom: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    border-bottom-color: ${({ theme }) =>
      transparentize('0.8', theme.colors.primary.color)};
  }

  &.active {
    border-bottom-color: ${({ theme }) => theme.colors.primary.color};
  }
`;

export const NavbarButton = styled(Button)`
  text-decoration: none;
  font-family: sans-serif;
  font-size: 14px;
  padding: 10px 20px 6px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 600;
`;
